import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2a868cce = () => interopDefault(import('../pages/aboutus.vue' /* webpackChunkName: "pages/aboutus" */))
const _88dff408 = () => interopDefault(import('../pages/accessibility.vue' /* webpackChunkName: "pages/accessibility" */))
const _a865359c = () => interopDefault(import('../pages/advocacy.vue' /* webpackChunkName: "pages/advocacy" */))
const _497ca50a = () => interopDefault(import('../pages/affiliate.vue' /* webpackChunkName: "pages/affiliate" */))
const _2a64d420 = () => interopDefault(import('../pages/ambassadors.vue' /* webpackChunkName: "pages/ambassadors" */))
const _fdd67bd2 = () => interopDefault(import('../pages/assembly-guide.vue' /* webpackChunkName: "pages/assembly-guide" */))
const _b8a20b40 = () => interopDefault(import('../pages/bicycleshops.vue' /* webpackChunkName: "pages/bicycleshops" */))
const _5a90c93d = () => interopDefault(import('../pages/branding.vue' /* webpackChunkName: "pages/branding" */))
const _f7a2c7be = () => interopDefault(import('../pages/buckupforbikes.vue' /* webpackChunkName: "pages/buckupforbikes" */))
const _7720412d = () => interopDefault(import('../pages/changePassword.vue' /* webpackChunkName: "pages/changePassword" */))
const _1e363b0e = () => interopDefault(import('../pages/claims.vue' /* webpackChunkName: "pages/claims" */))
const _cea7d686 = () => interopDefault(import('../pages/claimsform.vue' /* webpackChunkName: "pages/claimsform" */))
const _2162b877 = () => interopDefault(import('../pages/confirmation.vue' /* webpackChunkName: "pages/confirmation" */))
const _f28379a6 = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _9cae1caa = () => interopDefault(import('../pages/dimensionsandrates.vue' /* webpackChunkName: "pages/dimensionsandrates" */))
const _ca8b2840 = () => interopDefault(import('../pages/e-bike-shipping.vue' /* webpackChunkName: "pages/e-bike-shipping" */))
const _0fd1017a = () => interopDefault(import('../pages/event-shipping.vue' /* webpackChunkName: "pages/event-shipping" */))
const _f07307c0 = () => interopDefault(import('../pages/forgotPassword.vue' /* webpackChunkName: "pages/forgotPassword" */))
const _0fccd136 = () => interopDefault(import('../pages/guarantee.vue' /* webpackChunkName: "pages/guarantee" */))
const _f231c27c = () => interopDefault(import('../pages/history.vue' /* webpackChunkName: "pages/history" */))
const _4f0fbf41 = () => interopDefault(import('../pages/international_bicycle_shipping.vue' /* webpackChunkName: "pages/international_bicycle_shipping" */))
const _2eaa4bd0 = () => interopDefault(import('../pages/jobs.vue' /* webpackChunkName: "pages/jobs" */))
const _500d2497 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _749ec65b = () => interopDefault(import('../pages/pack.vue' /* webpackChunkName: "pages/pack" */))
const _2af90f06 = () => interopDefault(import('../pages/packing-guide.vue' /* webpackChunkName: "pages/packing-guide" */))
const _6e1c2161 = () => interopDefault(import('../pages/pre-confirmation.vue' /* webpackChunkName: "pages/pre-confirmation" */))
const _e2f49d30 = () => interopDefault(import('../pages/privacypolicy.vue' /* webpackChunkName: "pages/privacypolicy" */))
const _7c833157 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _739183ce = () => interopDefault(import('../pages/QBPtopshop.vue' /* webpackChunkName: "pages/QBPtopshop" */))
const _68c3f46c = () => interopDefault(import('../pages/refundpolicy.vue' /* webpackChunkName: "pages/refundpolicy" */))
const _67c618e5 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _d7f9f34c = () => interopDefault(import('../pages/registerthankyou.vue' /* webpackChunkName: "pages/registerthankyou" */))
const _01ef7dca = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _291174fe = () => interopDefault(import('../pages/ship.vue' /* webpackChunkName: "pages/ship" */))
const _d5ce76bc = () => interopDefault(import('../pages/shippingpolicy.vue' /* webpackChunkName: "pages/shippingpolicy" */))
const _225d648e = () => interopDefault(import('../pages/staff.vue' /* webpackChunkName: "pages/staff" */))
const _72e1f669 = () => interopDefault(import('../pages/sustainability.vue' /* webpackChunkName: "pages/sustainability" */))
const _e52c9fce = () => interopDefault(import('../pages/termsofservice.vue' /* webpackChunkName: "pages/termsofservice" */))
const _12a94de2 = () => interopDefault(import('../pages/timeout.vue' /* webpackChunkName: "pages/timeout" */))
const _3bf1350e = () => interopDefault(import('../pages/track.vue' /* webpackChunkName: "pages/track" */))
const _5d45def9 = () => interopDefault(import('../pages/tracking.vue' /* webpackChunkName: "pages/tracking" */))
const _7dc5d220 = () => interopDefault(import('../pages/trustpilot-reviews.vue' /* webpackChunkName: "pages/trustpilot-reviews" */))
const _906f6a12 = () => interopDefault(import('../pages/workwithus.vue' /* webpackChunkName: "pages/workwithus" */))
const _6acad6c8 = () => interopDefault(import('../pages/youraccount.vue' /* webpackChunkName: "pages/youraccount" */))
const _48abf0fd = () => interopDefault(import('../pages/youraddresses.vue' /* webpackChunkName: "pages/youraddresses" */))
const _193b2f14 = () => interopDefault(import('../pages/yourboxes.vue' /* webpackChunkName: "pages/yourboxes" */))
const _1712061a = () => interopDefault(import('../pages/yourorders.vue' /* webpackChunkName: "pages/yourorders" */))
const _d8e209bc = () => interopDefault(import('../pages/yourpayments.vue' /* webpackChunkName: "pages/yourpayments" */))
const _542c506a = () => interopDefault(import('../pages/yoursearches.vue' /* webpackChunkName: "pages/yoursearches" */))
const _d3fcbb02 = () => interopDefault(import('../pages/events/_event.vue' /* webpackChunkName: "pages/events/_event" */))
const _05c36ba6 = () => interopDefault(import('../pages/partners/_event.vue' /* webpackChunkName: "pages/partners/_event" */))
const _60d8b4d4 = () => interopDefault(import('../pages/your-order-details/_id.vue' /* webpackChunkName: "pages/your-order-details/_id" */))
const _27d579ea = () => interopDefault(import('../pages/event2/_event/_id.vue' /* webpackChunkName: "pages/event2/_event/_id" */))
const _f334fd00 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _74fa7346 = () => interopDefault(import('../pages/_event.vue' /* webpackChunkName: "pages/_event" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aboutus",
    component: _2a868cce,
    name: "aboutus"
  }, {
    path: "/accessibility",
    component: _88dff408,
    name: "accessibility"
  }, {
    path: "/advocacy",
    component: _a865359c,
    name: "advocacy"
  }, {
    path: "/affiliate",
    component: _497ca50a,
    name: "affiliate"
  }, {
    path: "/ambassadors",
    component: _2a64d420,
    name: "ambassadors"
  }, {
    path: "/assembly-guide",
    component: _fdd67bd2,
    name: "assembly-guide"
  }, {
    path: "/bicycleshops",
    component: _b8a20b40,
    name: "bicycleshops"
  }, {
    path: "/branding",
    component: _5a90c93d,
    name: "branding"
  }, {
    path: "/buckupforbikes",
    component: _f7a2c7be,
    name: "buckupforbikes"
  }, {
    path: "/changePassword",
    component: _7720412d,
    name: "changePassword"
  }, {
    path: "/claims",
    component: _1e363b0e,
    name: "claims"
  }, {
    path: "/claimsform",
    component: _cea7d686,
    name: "claimsform"
  }, {
    path: "/confirmation",
    component: _2162b877,
    name: "confirmation"
  }, {
    path: "/contact-us",
    component: _f28379a6,
    name: "contact-us"
  }, {
    path: "/dimensionsandrates",
    component: _9cae1caa,
    name: "dimensionsandrates"
  }, {
    path: "/e-bike-shipping",
    component: _ca8b2840,
    name: "e-bike-shipping"
  }, {
    path: "/event-shipping",
    component: _0fd1017a,
    name: "event-shipping"
  }, {
    path: "/forgotPassword",
    component: _f07307c0,
    name: "forgotPassword"
  }, {
    path: "/guarantee",
    component: _0fccd136,
    name: "guarantee"
  }, {
    path: "/history",
    component: _f231c27c,
    name: "history"
  }, {
    path: "/international_bicycle_shipping",
    component: _4f0fbf41,
    name: "international_bicycle_shipping"
  }, {
    path: "/jobs",
    component: _2eaa4bd0,
    name: "jobs"
  }, {
    path: "/login",
    component: _500d2497,
    name: "login"
  }, {
    path: "/pack",
    component: _749ec65b,
    name: "pack"
  }, {
    path: "/packing-guide",
    component: _2af90f06,
    name: "packing-guide"
  }, {
    path: "/pre-confirmation",
    component: _6e1c2161,
    name: "pre-confirmation"
  }, {
    path: "/privacypolicy",
    component: _e2f49d30,
    name: "privacypolicy"
  }, {
    path: "/profile",
    component: _7c833157,
    name: "profile"
  }, {
    path: "/QBPtopshop",
    component: _739183ce,
    name: "QBPtopshop"
  }, {
    path: "/refundpolicy",
    component: _68c3f46c,
    name: "refundpolicy"
  }, {
    path: "/register",
    component: _67c618e5,
    name: "register"
  }, {
    path: "/registerthankyou",
    component: _d7f9f34c,
    name: "registerthankyou"
  }, {
    path: "/reset-password",
    component: _01ef7dca,
    name: "reset-password"
  }, {
    path: "/ship",
    component: _291174fe,
    name: "ship"
  }, {
    path: "/shippingpolicy",
    component: _d5ce76bc,
    name: "shippingpolicy"
  }, {
    path: "/staff",
    component: _225d648e,
    name: "staff"
  }, {
    path: "/sustainability",
    component: _72e1f669,
    name: "sustainability"
  }, {
    path: "/termsofservice",
    component: _e52c9fce,
    name: "termsofservice"
  }, {
    path: "/timeout",
    component: _12a94de2,
    name: "timeout"
  }, {
    path: "/track",
    component: _3bf1350e,
    name: "track"
  }, {
    path: "/tracking",
    component: _5d45def9,
    name: "tracking"
  }, {
    path: "/trustpilot-reviews",
    component: _7dc5d220,
    name: "trustpilot-reviews"
  }, {
    path: "/workwithus",
    component: _906f6a12,
    name: "workwithus"
  }, {
    path: "/youraccount",
    component: _6acad6c8,
    name: "youraccount"
  }, {
    path: "/youraddresses",
    component: _48abf0fd,
    name: "youraddresses"
  }, {
    path: "/yourboxes",
    component: _193b2f14,
    name: "yourboxes"
  }, {
    path: "/yourorders",
    component: _1712061a,
    name: "yourorders"
  }, {
    path: "/yourpayments",
    component: _d8e209bc,
    name: "yourpayments"
  }, {
    path: "/yoursearches",
    component: _542c506a,
    name: "yoursearches"
  }, {
    path: "/events/:event?",
    component: _d3fcbb02,
    name: "events-event"
  }, {
    path: "/partners/:event?",
    component: _05c36ba6,
    name: "partners-event"
  }, {
    path: "/your-order-details/:id?",
    component: _60d8b4d4,
    name: "your-order-details-id"
  }, {
    path: "/event2/:event?/:id?",
    component: _27d579ea,
    name: "event2-event-id"
  }, {
    path: "/",
    component: _f334fd00,
    name: "index"
  }, {
    path: "/:event",
    component: _74fa7346,
    name: "event"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
