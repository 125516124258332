import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeLayers,
  FontAwesomeLayersText,
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

import {
  faPlus as freeFasFaPlus,
  faMinus as freeFasFaMinus,
  faXmark as freeFasFaXmark,
  faCheck as freeFasFaCheck,
  faAngleUp as freeFasFaAngleUp,
  faAngleDown as freeFasFaAngleDown,
  faLock as freeFasFaLock,
  faLocationDot as freeFasFaLocationDot,
  faAngleRight as freeFasFaAngleRight,
  faAngleLeft as freeFasFaAngleLeft,
  faGear as freeFasFaGear,
  faRotateRight as freeFasFaRotateRight,
  faFilePdf as freeFasFaFilePdf,
  faFileLines as freeFasFaFileLines,
  faFileArrowUp as freeFasFaFileArrowUp,
  faUpload as freeFasFaUpload,
  faDownload as freeFasFaDownload,
  faCircleInfo as freeFasFaCircleInfo
} from '@fortawesome/free-solid-svg-icons'

library.add(
  freeFasFaPlus,
  freeFasFaMinus,
  freeFasFaXmark,
  freeFasFaCheck,
  freeFasFaAngleUp,
  freeFasFaAngleDown,
  freeFasFaLock,
  freeFasFaLocationDot,
  freeFasFaAngleRight,
  freeFasFaAngleLeft,
  freeFasFaGear,
  freeFasFaRotateRight,
  freeFasFaFilePdf,
  freeFasFaFileLines,
  freeFasFaFileArrowUp,
  freeFasFaUpload,
  freeFasFaDownload,
  freeFasFaCircleInfo
)

config.autoAddCss = false

Vue.component('Fa', FontAwesomeIcon)
Vue.component('FaLayers', FontAwesomeLayers)
Vue.component('FaLayersText', FontAwesomeLayersText)
