export class Default {
  public display!: string;
  public sortOrder!: number;
  public code!: number;

  public constructor(init?: Partial<Default>) {
    Object.assign(this, init);
  }
}

export class Category {
  public entryId!: string;
  public display!: string;
  public sortOrder!: number;
  public code!: number;

  public constructor(init?: Partial<Default>) {
    Object.assign(this, init);
  }
}

export class Preset {
  public type!: string;
  public name!: string;
  public length!: number;
  public width!: number;
  public height!: number;
  public weight!: number;
  public linearUnit!: string;
  public weightUnit!: string;
  public isEbike!: boolean;
  public isExact!: boolean;
  public isDangerous!: boolean;
  public sortOrder!: number;

  public constructor(init?: Partial<Preset>) {
    Object.assign(this, init);
  }
}

export class UserPreset {
  public name!: string;
  public length!: number;
  public width!: number;
  public height!: number;
  public weight!: number;
  public linearUnit!: string;
  public weightUnit!: string;
  public isEbike!: boolean;
  public hasBattery!: number;
  public coverage!: number;
  public description!: string;
  public outsideDescription!: string;
  public categoryCode!: number;
  public marketValue!: number;

  public constructor(init?: Partial<Preset>) {
    Object.assign(this, init);
  }
}

export class Insurance {
  public entryId!: string;
  public display!: string;
  public coverage!: number;
  public cost!: number;
  public sortOrder!: number;
  public afterText?: string | null;

  public constructor(init?: Partial<Insurance>) {
    Object.assign(this, init);
  }
}

export class PickupOption {
  public display!: string;
  public sortOrder!: number;
  public hasPickup!: number;
  public code!: string;

  public constructor(init?: Partial<PickupOption>) {
    Object.assign(this, init);
  }
}

export class Purpose {
  public entryId!: string;
  public display!: string;
  public sortOrder!: number;
  public code!: number;

  public constructor(init?: Partial<Default>) {
    Object.assign(this, init);
  }
}

export class Country {
  public code!: string;
  public name!: string;
  public active!: boolean;
  // for selections
  public id!: string;
  public label!: string;

  public constructor(init?: Partial<Country>) {
    Object.assign(this, init);
  }
}

export class State {
  public stateprovcode!: string;
  public stateprov!: string;
  public country!: string;
  // for selections
  public id!: string;
  public label!: string;

  public constructor(init?: Partial<Country>) {
    Object.assign(this, init);
  }
}
